.App {
  text-align: center;
  height: 100vh;
}

.App-header {
  background-color: #5c81b5;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap"); */

:root {
  --main-darklg: #1d2635;
  --main-dark: #161d29;
  --primary-color: #2f80ec;
  --main-light: #eeeeee;
  font-family: "Poppins", sans-serif;
}

* {
  margin: 0;
  padding: 0;
}

.header {
  background-color: var(--main-darklg);
}

.logo > h3 {
  color: var(--main-light);
}

.main {
  overflow: hidden;
  height: 94.25vh;
  display: flex;
}

.main__left {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.videos__group {
  flex-grow: 1;
  padding: 1rem;
  background-color: var(--main-dark);
}

video {
  object-fit: cover;
}

.options {
  padding: 1rem;
  display: flex;
  background-color: var(--main-darklg);
}
.options-hidden {
  height: 0;
  padding: 0;
  overflow: hidden;
}

.options__left {
  display: flex;
}

.options__right {
  margin-left: auto;
}

.options__button {
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  height: 50px;
  border-radius: 5px;
  color: var(--main-light);
  font-size: 1.2rem;
  width: 50px;
  margin: 0 0.5rem;
}

.background__red {
  background-color: #f6484a;
}

.background__blue_title {
  background-color: #8997ac6b;
}

.main__right {
  display: flex;
  flex-direction: column;
  background-color: #242f41;
}

.main__chat_window {
  flex-grow: 1;
}

.main__message_container > input {
  height: 50px;
  flex: 1;
  font-size: 1rem;
  border-radius: 5px;
  padding-left: 20px;
  border: none;
}

.messages {
  display: flex;
  flex-direction: column;
  margin: 1.5rem;
}

.message {
  display: flex;
  flex-direction: column;
}

.message > b {
  color: #eeeeee;
  display: flex;
  align-items: center;
  text-transform: capitalize;
}

.message > b > i {
  margin-right: 0.7rem;
  font-size: 1.5rem;
}

.message > span {
  background-color: #eeeeee;
  margin: 1rem 0;
  padding: 1rem;
  border-radius: 5px;
}

#video-grid {
  display: grid;
  grid-template-columns: 1fr 0fr 0fr 1fr;
}

#video-scroll {
  display: flex;
}

#showChat {
  display: flex;
}

.header__back {
  display: none;
  position: absolute;
  font-size: 1.3rem;
  top: 17px;
  left: 28px;
  color: #fff;
}

@media (max-width: 700px) {
  .main__right {
    display: none;
  }
  .main__left {
    width: 100%;
    flex: 1;
  }

  video {
    height: auto;
    width: 100%;
  }

  #showChat {
    display: flex;
  }
}

.home {
  background-color: #8eb9f20f;
}

.gutter {
  background-color: #eee;

  background-repeat: no-repeat;
  background-position: 50%;
}

.gutter.gutter-horizontal {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAeCAYAAADkftS9AAAAIklEQVQoU2M4c+bMfxAGAgYYmwGrIIiDjrELjpo5aiZeMwF+yNnOs5KSvgAAAABJRU5ErkJggg==");
  cursor: col-resize;
}

.gutter.gutter-vertical {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAFAQMAAABo7865AAAABlBMVEVHcEzMzMzyAv2sAAAAAXRSTlMAQObYZgAAABBJREFUeF5jOAMEEAIEEFwAn3kMwcB6I2AAAAAASUVORK5CYII=");
  cursor: row-resize;
}

video {
  z-index: 999;
}

.video__main-content {
  height: 500px;
  width: 700px;
  position: relative;
  display: flex;
  background-color: lightslategray;
  justify-content: center;
  min-width: 300px;
}

.video__main-scroll {
  height: 200px;
  width: 300px;
  background-color: lightslategray;
  position: relative;
  display: flex;
  justify-content: center;
  min-width: 300px;
}

.video__content {
  height: 500px;
  width: 700px;
  min-width: 300px;
}

.video__scroll {
  height: 200px;
  width: 300px;
}

.video__main-username {
  position: absolute;
  top: 0.75rem;
  left: 1.25rem;
  border-radius: 0.25rem;
  padding: 0.25rem;
  color: white;
  z-index: 999;
  background-color: #8997ac6b;
}
.video__main-fullsize {
  position: absolute;
  bottom: 0.75rem;
  border-radius: 0.25rem;
  padding: 0.25rem;
  color: white;
  z-index: 999;
  background-color: #8997ac6b;
  right: 1.25rem;
  cursor: pointer;
}
.video__content-image {
  width: 70px;
  position: absolute;
  align-self: center;
}

.ReactModal__Overlay {
  z-index: 999;
}

/*video::-webkit-media-controls-play-button {}*/

/*video::-webkit-media-controls-volume-slider {}*/

/*video::-webkit-media-controls-mute-button {}*/

/*video::-webkit-media-controls-timeline {}*/

/*video::-webkit-media-controls-current-time-display {}*/
video::-webkit-media-controls-panel {
  display: none !important;
  opacity: 1 !important;
}
